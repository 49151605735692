import React from "react";
import { Link, graphql } from "gatsby";

import { connect } from "react-redux";
import Helmet from "react-helmet";


import { fadeIn, bounceInDown } from "react-animations";
import styled, { keyframes } from "styled-components";
import LazyLoad from "react-lazyload";

import { COLORS, FONT_FAMILIES } from "../utils/theme";

import {
  FEST_SPLASH_PERFORMERS_SPECIAL_SETS,
  FEST_SPLASH_PERFORMERS_TIER_ONE,
  FEST_SPLASH_PERFORMERS_TIER_TWO
} from "../constants/constants";

import Page from "../components/Layout/Page";
import {
  FullWidthContainer,
  FixedWidthContainer
} from "../components/Layout/ContentContainers";

import { StyledComponentsBreakpoints } from "../components/Layout/Responsive";

import Sponsors from "../components/Layout/Sponsors";
import SocialMediaComponent from "../components/SocialMedia";
import MailingListComponent from "../components/MailingList";
import AdComponent from "../components/Ad";
import Post from "../components/Post";
import { getShortStringFromDate } from "../utils/strings";


const getMetaTitle = () => {
  const pageTitle = "THE FEST 22 - October 25-27, 2024 - Gainesville, FL";
  return pageTitle;
};



class Fest22Home extends React.Component {
  render() {
    const { data } = this.props;
    const {
      latestNewsPost,
      latestNewsHeadlines
    } = data;
    const latestPost = latestNewsPost.edges[0].node;

    return (
      <Page
        showTitle="false"
        isHome
        noSponsorsTierOne
        noSocialMedia
        noLogo
        animatesIn
      >
        <Helmet title={getMetaTitle()} />

        <FullWidthContainer>
          <BackgroundImageContainer>
            <AboutStripe>
              <AboutStripeContentContainer>
                <SponsorsContainer>
                  <Sponsors tier={1} />
                </SponsorsContainer>
                <ImageContainer>
                  <img src="/images/logos/fest22-main.png" alt="The FEST 22" />
                  <img style={{ marginTop: -8, width: "80%" }} src="/images/logos/fest22-dates.png" alt="October 25th, 26th, 27th, 2024 - Gainesville, Florida" />

                </ImageContainer>


                <SplashLineupContainer>
                  <SplashPerformerList style={{ marginTop: 24 }}>
                    {FEST_SPLASH_PERFORMERS_TIER_ONE.slice(0, 4).map(performer => (
                      <SplashPerformer key={performer.label} className="embiggen">
                        <Link to={performer.url}>
                          {performer.label}
                          {performer.twoSets && <div style={{ display: "inline-block", color: "#fff", fontSize: 24, marginTop: -4, marginLeft: 2 }}>*</div>}
                          {performer.subtext && <SplashPerformerSubtext dangerouslySetInnerHTML={{ __html: performer.subtext }}></SplashPerformerSubtext>}
                        </Link>
                      </SplashPerformer>
                    ))}<br />
                    {FEST_SPLASH_PERFORMERS_TIER_ONE.slice(4).map(performer => (
                      <SplashPerformer key={performer.label}>
                        <Link to={performer.url}>
                          {performer.label}
                          {performer.twoSets && <div style={{ display: "inline-block", color: "#fff", fontSize: 20, marginTop: -4, marginLeft: 2 }}>*</div>}
                        </Link>
                      </SplashPerformer>
                    ))}<br />
                    {FEST_SPLASH_PERFORMERS_TIER_TWO.map(performer => (
                      <SplashPerformer key={performer.label} className="tierTwo">
                        <Link to={performer.url}>
                          {performer.label}
                          {performer.twoSets && <div style={{ display: "inline-block", color: "#fff", fontSize: 20, marginTop: -4, marginLeft: 2 }}>*</div>}
                        </Link>
                      </SplashPerformer>
                    ))}
                    <SplashPerformer key="MORE">
                      <Link to="/bands/">
                        <span style={{ color: "#FFF" }}>PLUS SO MANY MORE!!!</span>
                      </Link>
                    </SplashPerformer>
                    <hr style={{ height: 0, opacity: 0, margin: "2px auto" }} />

                    <SplashPerformer key="DOS">
                      <span style={{ color: "#FFF" }}>* PLAYING TWO SETS</span>
                    </SplashPerformer>
                  </SplashPerformerList>


                  <SpecialAlbumContainer>
                    <h5 style={{ textAlign: "center", marginBottom: 16 }}><em>FEATURING THESE SUPER SPECIAL FULL ALBUM SETS:</em></h5>
                    <SpecialAlbumList>

                      {FEST_SPLASH_PERFORMERS_SPECIAL_SETS.slice(0, 1).map(performer => (
                        <SpecialAlbumListItem key={performer.label} className="longboi">
                          <Link to={performer.url}>
                            {performer.label}</Link>< br />
                          {" "}
                          {performer.subtext && <span dangerouslySetInnerHTML={{ __html: performer.subtext }}></span>}

                        </SpecialAlbumListItem>
                      ))}
                      {FEST_SPLASH_PERFORMERS_SPECIAL_SETS.slice(1).map(performer => (
                        <SpecialAlbumListItem key={performer.label}>
                          <Link to={performer.url}>
                            {performer.label}</Link>< br />
                          {" "}
                          {performer.subtext && <span dangerouslySetInnerHTML={{ __html: performer.subtext }}></span>}

                        </SpecialAlbumListItem>
                      ))}
                    </SpecialAlbumList>
                  </SpecialAlbumContainer>



                </SplashLineupContainer>

                <SplashPerformerContainer>
                  <SplashPerformerList style={{ margin: "12px auto 64px" }}>
                    <a target="_blank" style={{color: "#FFF"}} href="https://wl.seetickets.us/event/THE-FEST-22/591038?afflky=TheFest">
                      <SplashPerformer className="closer">FEST 22 3-DAY PASSES<br />ARE SOLD OUT!</SplashPerformer>
                      <SplashPerformer className="closer"><a href="/news/fest-22-announces-more-bands-sells-out-three-day-passes-shares-lineup-for-kick-off-show">CLICK HERE FOR SINGLE SHOW TICKET DETAILS (AVAILABLE JULY 1)</a></SplashPerformer>
                    </a>
                  </SplashPerformerList>

                </SplashPerformerContainer>

              </AboutStripeContentContainer>

            </AboutStripe>

            {/* 
            <FixedWidthContainer>
              <TicketCTAContainer>
                <TicketCTA>
                  <p style={{ width: "100%", background: "rgba(255, 255, 255, 0.95)", color: "#000" }}>ORDER OUR REMAINING FEST 21 MERCHANDISE<br />AT <a target="_blank" href="https://pillowheadmerch.com/collections/the-fest-merch">PILLOWHEADMERCH.COM</a></p>
                  {/*
            
                <!--
                <p style={{width: "100%", background: "rgba(255, 255, 255, 0.95)", color: "#000"}}>BUY YOUR FEST 21 KICKOFF PARTY (THURS NIGHT) TICKETS:<br /><a target="_blank" href="https://www.seetickets.us/event/Bo-Diddley-Plaza-Descendents-Into-It-Over-It-and-more/559703">BO DIDDLEY PLAZA</a> / <a target="_blank" href="https://www.seetickets.us/event/Looseys-Taking-Meds-Carly-Cosgrove-and-more/561406">LOOSEY'S</a></p>
                  <br />
                  <p style={{width: "100%", background: "rgba(255, 255, 255, 0.95)", color: "#000"}}>BUY FEST 21 SINGLE SHOW TICKETS:<br /><a target="_blank" href="https://www.Seetickets.us/TheFest21">SEETICKETS.US</a></p>
                  <br />
                  
                  <p style={{width: "100%", background: "rgba(255, 255, 255, 0.95)", color: "#000"}}>BOOK YOUR OFFICIAL FEST 21 HOTEL: <br /> <a target="_blank" href="https://thefestfl.limitedrun.com/categories/hotels">THEFESTFL.LIMITEDRUN.COM</a></p>
                  
                  <br />
                  <p style={{ fontSize: "22px", fontStyle: "italic" }}>3-DAY PASSES SOLD OUT!<br /><a target="_blank" href="https://twitter.com/thefestfl/status/1659023904403337217">READ THIS TO SEE HOW YOU CAN STILL ATTEND!</a></p>
                -->

                </TicketCTA>
              </TicketCTAContainer>
            </FixedWidthContainer>
                */}
          </BackgroundImageContainer>
        </FullWidthContainer>

        <SocialMediaComponent />

        <FixedWidthContainer>
          <AdComponent size="large" style={{ marginBottom: "16px" }} />
        </FixedWidthContainer>

        <LazyLoad>
          <FixedWidthContainer>
            <NewsContainer>
              <LatestNewsPostContainer>
                <Post post={latestPost} />
              </LatestNewsPostContainer>
              <OtherNewsContainer>
                <HeadlineListTitle>#FEST22 News</HeadlineListTitle>
                <HeadlineList>
                  {latestNewsHeadlines.edges.map((post, index) => {
                    const postNode = post.node;
                    return (
                      <li key={postNode.id}>
                        <a
                          href={`/news/${postNode.slug}`}
                          dangerouslySetInnerHTML={{
                            __html: `${getShortStringFromDate(
                              postNode.acf.external_published_date
                                ? postNode.acf.external_published_date
                                : postNode.date
                            )}: ${postNode.title}`,
                          }}
                        />
                      </li>
                    );
                  })}
                </HeadlineList>
                <hr />
                <MailingListComponent />
              </OtherNewsContainer>
            </NewsContainer>
          </FixedWidthContainer>

          <FixedWidthContainer>
            <AdComponent size="small" style={{ marginBottom: "16px" }} />
          </FixedWidthContainer>
        </LazyLoad>
      </Page >
    );
  }
}

const fadeInAnimation = keyframes`${fadeIn}`;
const bounceInAnimation = keyframes`${bounceInDown}`;

const AboutStripe = styled.div`
  position: relative;
  padding-top: 60px;
  padding-bottom: 4px;
  
  ${StyledComponentsBreakpoints.Phone` 
     padding-top: 52px;
     margin-top: 72px;
     padding-bottom: 1px;
  `};
`;

const AboutStripeContentContainer = styled.div`
  position: relative;
  animation: 0.420s ${bounceInAnimation};

  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  ${StyledComponentsBreakpoints.Phone` 
    animation: unset;
    display: block;
  `};
`;

const ImageContainer = styled.div`
  margin: 0 auto 12px;

  ${StyledComponentsBreakpoints.Phone` 
     margin-top: 0;
  `};
  min-width: 320px;
  max-width: 400px;
  text-align: center;
  
  img {
    max-width: 100%;
  }
`;

const SplashPerformerContainer = styled.div`
  animation: 0.420s ${fadeInAnimation};
  margin: 0px auto 0;
  text-align: center;
  max-width: 800px;
}`;

const SplashLineupContainer = styled.div`
  animation: 0.420s ${fadeInAnimation};
  margin: 0px auto 0;
  text-align: center;
  
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div:nth-of-type(1) {
    max-width: 800px;
    ${StyledComponentsBreakpoints.Tablet` 
      width: 100%;
      max-width: 100%;
    `};
  }
  > div:nth-of-type(2) {
    margin-top: 32px;
    ${StyledComponentsBreakpoints.Tablet` 
      margin-top: 0;
    `};
  }
}`;

const SplashPerformerSubtext = styled.div`
  ${FONT_FAMILIES.headline};

  font-style: italic;
  font-size: 15px;
  margin-top: -1px;
  margin-bottom: 4px;
  color: ${COLORS.lightgrey.rgba.replace(", 1)", ", 0.8)")};
`;

const SplashPerformerList = styled.div`
  ${StyledComponentsBreakpoints.Phone` 
    margin-bottom: 24px;
  `}
`;

const SplashPerformer = styled.span`
  cursor: pointer;
  cursor: hand;
  display: inline-block;
  vertical-align: top;

  background: ${COLORS.black.rgba.replace(", 1)", ", 0.98)")};

  &:hover {
    background-color: ${COLORS.darkgrey.rgba.replace(", 1)", ", 0.95)")};
  }

  ${FONT_FAMILIES.headline};

  max-width: 420px;  
  padding: 4px 12px 5px;
  margin: 1px 4px;
  transform: rotateZ(0.69deg);
  &:nth-child(odd) {

    transform: rotateZ(-0.666deg);
  }

  
  letter-spacing: 1px;
  font-size: 18px;
  text-transform: uppercase;

  &:nth-child(odd) {
    a {
      color: ${COLORS.blue.rgba.replace(", 1)", ", 0.95)")};
      text-decoration: none;

      &:hover {
        color: ${COLORS.white.rgba};
      }
    }
  } 
  &:nth-child(even) {
    a {
      color: ${COLORS.red.rgba.replace(", 1)", ", 0.95)")};
      text-decoration: none;

      &:hover {
        color: ${COLORS.white.rgba};
      }
    }
  } 
font-weight: 400;
  &.embiggen {
    font-size: 28px;
    font-weight: 800;
  }
  &.closer {
    margin-top: 16px;
    font-style: italic;
    font-size: 24px;
    cursor: inherit;
    a {
      color: #CCC;
    }
  }
  &.tierTwo {
    font-size: 15px;
    line-height: 1rem;
    margin: 2px 2px;
  }

  ${StyledComponentsBreakpoints.Phone` 
      font-size: 18px;
      margin: 1px 4px;
      padding: 2px 4px;
      
      &.tierTwo {
        font-size: 16px;
        line-height: 1rem;
        margin: 2px 2px;
        padding: 2px 4px;
      }
    }
  `};
`;

const SpecialAlbumContainer = styled.div`
  margin: 0 auto;
  padding: 8px 12px 8px ;
  text-align: left;
  background: ${COLORS.black.rgba.replace(", 1)", ", 0.9)")};

  max-width: 500px;
  ${StyledComponentsBreakpoints.Phone` 
    max-width: 90%;
  `};

  > h5 { font-size: 10px !important; }

`;


const SpecialAlbumList = styled.ul`
  list-style-type: none;
  text-align: center;
  `;

const SpecialAlbumListItem = styled.li`
  
  &.longboi { min-width: 100%; }
  float: left; 
  
  margin-left: 0;
  margin-bottom: 12px;
  text-transform: uppercase;
  font-size: 12px !important;
  ${FONT_FAMILIES.headline};

  width: 45%;
  margin-right: 5%;
  ${StyledComponentsBreakpoints.Phone` 
    width: 100%;
  `};

  > span {
    font-size: 12px !important;${FONT_FAMILIES.headline};
  }

  a {
    font-weight: 800;
  }


  &:nth-child(odd) {
    a {
      color: ${COLORS.blue.rgba.replace(", 1)", ", 0.95)")};
      text-decoration: none;

      &:hover {
        color: ${COLORS.white.rgba};
      }
    }
  } 
  &:nth-child(even) {
    a {
      color: ${COLORS.red.rgba.replace(", 1)", ", 0.95)")};
      text-decoration: none;

      &:hover {
        color: ${COLORS.white.rgba};
      }
    }
  } 
`;






const TicketCTAContainer = styled.div`
  margin: 36px auto 36px;
  padding:  0;
  text-align: center;
  ${StyledComponentsBreakpoints.Phone` 
    padding: 16px 0 0;
  `};
`;
const TicketCTA = styled.div`
  p { 
    display: block;
    width: fit-content;
    margin: 0px auto 4px;
    padding: 8px 24px;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: 1px;
    text-align: center;
    ${FONT_FAMILIES.headline};
    background-color: ${COLORS.black.rgba.replace(", 1)", ", 0.85)")};
    color: ${COLORS.lightgrey.rgba};

    a {
      ${FONT_FAMILIES.headline};
      color: ${COLORS.blue.rgba};
    }
  }
`;

const SponsorsContainer = styled.div`
  margin: 24px auto;
  text-align: center;
  .sponsorsTierOne {

    ${StyledComponentsBreakpoints.Phone` 
    `};

    img {
      max-height: 60px;
      margin: 0 2px;

      filter: invert(18%) !important;

      &:hover {
        filter: invert(0%) !important;
      }

      ${StyledComponentsBreakpoints.Phone` 
        max-height: 48px;
      `};
    }
  }
`;

const NewsContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const LatestNewsPostContainer = styled.div`
  order: 2;
  flex: 3 1 75%;
  ${StyledComponentsBreakpoints.Phone`
    order: 1;
    width: 100%;
    flex: 1 1 100%;
  `};
`;
const OtherNewsContainer = styled.div`
  order: 1;
  flex: 1 1 20%;
  margin-right: 5%;

  ${StyledComponentsBreakpoints.Phone`
    order: 2;
    margin: 2rem 0;
    padding: 0;
    flex: 1 1 100%;
  `};
`;
const HeadlineListTitle = styled.h6`
  font-size: 13px;
`;
const HeadlineList = styled.ul`
  margin-bottom: 16px;
  list-style-type: none;

  a {
    font-family: "Rubik", "League Gothic", "Rubik", -apple-system, BlinkMacSystemFont,
      "avenir next", avenir, helvetica, "helvetica neue", Ubuntu, "segoe ui",
      arial, sans-serif;
    text-transform: uppercase;
    font-size: 15px;
  }
  li {
    margin-left: 0;
    padding-left: 0;
  }
`;


const BackgroundImageContainer = styled.div`
  background-image: url("/images/fest22-bg-splash.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;


const ResponsiveVideoPlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query Fest22HomeQuery {
    latestNewsPost: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: "FEST 22" } } } }
      sort: { fields: [date], order: DESC }
      limit: 1
    ) {
      edges {
        node {
          date
          slug
          title
          modified
          excerpt
          id
          content
          categories {
            name
          }
          acf {
            external_published_date
          }
        }
      }
    }

    latestNewsHeadlines: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: "FEST 22" } } } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          date
          slug
          title
          acf {
            external_published_date
          }
        }
      }
    }
  }
`;

const connectedFestHome = connect()(Fest22Home);
export default connectedFestHome;
