/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from "react";

import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";

import Input from "@material-ui/core/Input";

import { StyledComponentsBreakpoints } from "./Layout/Responsive";

import { FullWidthContainer } from "./Layout/ContentContainers";

import { FONT_FAMILIES, COLORS } from "../utils/theme";

import Button from "./Forms/Button";

class MailingListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      name: ""
    };

    this.formRef = React.createRef();

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  validateForm() {
    this.setState({});
  }

  handleKeyPress(event) {
    if (event.charCode === 13) {
      this.formRef.current.submit();
    }
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handleSubmitClick() {
    this.formRef.current.submit();
  }

  render() {
    const { name, email } = this.state;
    return (
      <FullWidthContainer>
        <MailingListWrapper id="newsletter-signup" className="anchor-offset">
          <MailingListContainer>
            <Headline>Sign Up For THE FEST Newsletter!</Headline>

            <form
              ref={this.formRef}
              action="//newsletters.limitedrun.com/subscribe?store=thefestfl"
              method="post"
              target="_blank"
            >
              <FormFieldWrapper>
                <label id="name" htmlFor="name">
                  <Input
                    id="name"
                    name="name"
                    placeholder="First & Last Name"
                    inputProps={{
                      "aria-label": "First & Last Name"
                    }}
                    value={name}
                    onChange={this.handleNameChange}
                    onKeyPress={this.handleKeyPress}
                    fullWidth
                  />
                </label>
              </FormFieldWrapper>
              <FormFieldWrapper>
                <label id="email" htmlFor="email">
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    inputProps={{
                      "aria-label": "Email Address"
                    }}
                    value={email}
                    onChange={this.handleEmailChange}
                    onKeyPress={this.handleKeyPress}
                    fullWidth
                  />
                </label>
              </FormFieldWrapper>
              <SubmitWrapper>
                <Button onClick={this.handleSubmitClick}>Sign Up</Button>
              </SubmitWrapper>
            </form>
          </MailingListContainer>
        </MailingListWrapper>
      </FullWidthContainer>
    );
  }
}

const fadeInAnimation = keyframes`${fadeIn}`;

const FormFieldWrapper = styled.div`
  margin: 12px 0 0;
  display: inline-block;

  input {
    font: inherit;
    color: #eee;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 6px 0 7px;
    display: block;
    min-width: 0;
    box-sizing: content-box;
    background: none;
    -webkit-tap-highlight-color: transparent;
    font-family: "Rubik", "Roboto Condensed", "Roboto", -apple-system, BlinkMacSystemFont, "segoe ui", arial,
    "ubuntu", sans-serif;
  
  }

  ${StyledComponentsBreakpoints.Phone` 
  margin: 8px 0;
    width: 100%;
    display: block;
  `};
`;
const SubmitWrapper = styled.div`
  display: inline-block;
`;

const MailingListWrapper = styled.div`

  display: block;
  width: 100%;
  animation: 0.420s ${fadeInAnimation};
  text-align: center;
`;

const MailingListContainer = styled.div`

  text-align: left;
  margin: 0 auto;
`;

const Headline = styled.span`
  ${FONT_FAMILIES.headline};

  display: block;
  color: #eee;
  font-size: 16px;
  
`;

export default MailingListComponent;
