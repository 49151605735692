import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { useStaticDomain, getStringFromDate } from "../utils/strings";
import { FONT_FAMILIES } from "../utils/theme"

const Post = props => {
  const { post } = props;
  const date = (post.acf || {}).external_published_date ? post.acf.external_published_date : post.date;

  return (
    <article>
      <header>
        {/* <Link className="post-link" to={`/news/${post.slug}`} key={post.title}> */}
        
        
        {/* 
        new Date(date) < new Date('2020/07/05') && (
          <div style={{textAlign: "center"}}>
            <h3>
              *** This update was written before FEST 19 was postponed to 2021. It may no longer be accurate. Please review the 
              {" "}
              <Link to="/news/fest-19-postponed-to-2021">postponement announcement</Link>
              {' '}
              and the site
              {' '}
              <Link to="/faq">FAQ</Link>
              {' '}
              to get the most up-to-date information about FEST 19. ***
            </h3>
            <hr />
          </div>
        ) */}

        <PostDate>{getStringFromDate(date)}</PostDate>
        <Headline dangerouslySetInnerHTML={{ __html: post.title }} />
        {/* </Link> */}
      </header>

      <PostContent
        dangerouslySetInnerHTML={{ __html: useStaticDomain(post.content) }}
      />
    </article>
  );
};

const Headline = styled.h2`
  max-width: 50rem;
`;

const PostDate = styled.h6`
  margin: 0 0 4px;
  color: #999;
`;

const PostContent = styled.section`
  img {
    max-width: 100%;
    height: auto;
  }
`;

export default Post;
